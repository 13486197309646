<template>
	<el-dialog title="" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false" @close="onResetForm('ruleForm')">
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download">{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}驻派工作模块</span>
		</div>
		<el-form label-position="top" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
			<el-form-item label="模块名称" prop="title">
				<el-input v-model.trim="ruleForm.title" :clearable="true" :disabled="isShowDetail" placeholder=" 请输入" maxlength="20"></el-input>
			</el-form-item>
			<el-form-item label="模块详情" prop="description">
				<el-input
					v-model.trim="ruleForm.description"
					:clearable="true"
					type="textarea"
					autosize
					:disabled="isShowDetail"
					placeholder=" 请输入"
				></el-input>
			</el-form-item>
			<el-form-item class="func-btn">
				<!-- <el-button type="primary" @click="onSave('ruleForm')">保存</el-button>
				<el-button @click="onResetForm('ruleForm')">取消</el-button> -->
				<el-button @click="onResetForm('ruleForm')" type="primary" v-if="isShowDetail">确 定</el-button>
				<el-button v-else type="primary" @click="onSave('ruleForm')">保存</el-button>
				<el-button v-if="!isShowDetail" @click="onResetForm('ruleForm')">取消</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
import { verifyPhone } from '@/utils/toolsValidate';
export default {
	components: {
		Upload: () => import('@/components/Upload/index.vue'),
		RsForm: () => import('@/components/RsForm/index.vue'),
	},
	data() {
		var checkPhone = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入联系方式'));
			} else if (!verifyPhone(value)) {
				callback(new Error('请输入正确的联系方式'));
			} else {
				callback();
			}
		};
		return {
			ruleForm: { userList: [{ name: '' }], medicalWorkerIds: [] },
			createdVisible: false,
			option: [],
			dictionaryId: '',
			rules: {
				title: [{ required: true, message: '请输入', trigger: 'blur' }],
				description: [{ required: true, message: '请输入', trigger: 'blur' }],
			},
			saveLoading: false,
			link: '',
		};
	},
	props: ['isShowDetail'],
	watch: {},
	created() {},
	methods: {
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveLoading = true;
					this.ruleForm.dictionaryId = this.dictionaryId;
					let queryApi = this.ruleForm.id ? this.$http.put(this.link, this.ruleForm) : this.$http.post(this.link, this.ruleForm);
					queryApi
						.then((res) => {
							if (res.data && res.data.success) {
								this.saveLoading = false;
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								this.$emit('getList');
								this.onResetForm('ruleForm');
							}
						})
						.catch(() => {
							this.saveLoading = false;
						});
				} else {
					this.$nextTick(() => {
						let isError = document.getElementsByClassName('is-error');
						// var a = $(isError[0]).find("input") //this.$refs.cmd.$el.querySelector('input')
						// a.focus()  光标定位到所在input
						isError[0].scrollIntoView({
							// 滚动到指定节点
							// 值有start,center,end，nearest
							block: 'center',
							// 值有auto、instant,smooth，缓动动画
							behavior: 'smooth',
						});
					});
					return false;
				}
			});
		},
		init(params) {
			console.log(params, '0909');
			this.link = params.link;
			if (params.row) {
				this.$http.get(this.link).then((res) => {
					this.$nextTick(() => {
						this.createdVisible = true;
					});

					this.ruleForm = res.data.collection[0];

					console.log(this.ruleForm);
				});

				return;
			}
			// 新增
			this.createdVisible = true;
			this.ruleForm = this.$options.data().ruleForm; //清空表单
		},

		// 重置
		onResetForm(formName) {
			this.createdVisible = false;
			this.$refs[formName].resetFields();
		},
	},
};
</script>

<style lang="scss" scoped>
.showBorder {
	border: 1px solid red;
}
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}

	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
}
/deep/ .el-button {
	margin-left: 16px;
}
.users-item {
	margin-bottom: 12px;
	display: grid;
	grid-template-columns: 136px 136px 136px 16px 16px;
	gap: 15px;
	i {
		line-height: 32px;
		color: #999;
		font-size: 16px;
		cursor: pointer;
	}
	i:hover {
		color: #1db9b1;
	}
	.icon-shanchu:hover {
		color: #f56c6c;
	}
}
.users-item:nth-last-child(1) {
	margin-bottom: 0;
}
.func-btn /deep/ .el-form-item__content {
	display: flex;
	justify-content: center;
}
.flex-tow {
	display: flex;
	align-items: center;
}
/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .w50 {
	width: calc(50% - 8px);
	display: inline-block;
}
/deep/ .el-textarea__inner {
	min-height: 64px !important;
	line-height: 18px !important;
	padding: 5px 12px !important;
}
</style>